import { Component, NgModule, ViewChild } from '@angular/core';
import { DxTreeViewModule, DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';

import { SharedModule } from '../../modules/shared/shared.module';
import DataSource from 'devextreme/data/data_source';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { genericCustomStoreService } from '../../services/genericCustomStore.service';
import { DxTreeListComponent } from 'devextreme-angular';


@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent   {
  @ViewChild(DxTreeListComponent, { static: true }) dxtree:DxTreeListComponent
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  currentLang:any;
  menu!: DxTreeViewComponent;
  data:any
  lists:any=[]
  sessiondata:any=null
  selectedIndex:number
  expendarray:number[]=[]
  tabs:any=[]
  focusedRowKey: number;
  constructor(public router:Router,public genericCustomStoreService:genericCustomStoreService) {
    this.currentLang = localStorage.getItem(
      this.LOCALIZATION_LOCAL_STORAGE_KEY
    );
    if(window.sessionStorage.getItem('data')!=null){
      this.sessiondata=sessionStorage.getItem("data");
      this.data=JSON.parse(this.sessiondata).navihationMenu;

      //foreach to get tap and handle menulist
      this.data.forEach((element:any) => {
        if(element.externalUrl.includes('tab')){
         element.tabs=[]
         this.data.forEach((ele:any)=>{
          if(ele.parentId==element.id)   {
            element.tabs.push(ele)
            this.data=this.data.filter((ele:{parentId:number})=>{
              return ele.parentId!=element.id
            })
          }
         })
         this.tabs.push(element.tabs)
        }
        this.genericCustomStoreService.sessiondata.next(this.tabs)
      });

      //sort list
      this.data.sort((a:{displayOrder:any,externalUrl:string}, b:{displayOrder:any,externalUrl:string}) =>a.displayOrder - b.displayOrder);
      this.lists = new DataSource({  
        store: this.data,  
  }); 

}
    else{
      this.router.navigate(['/login-form'])
    }
     
    // when refresh add active To path
    
    if(localStorage.getItem('activePath')!=null){
      let path=localStorage.getItem('activePath')
     let ele= this.data.filter((res:{externalUrl:string},id:number)=>{
       return res.externalUrl==path
      })
      this.focusedRowKey=ele[0]?.id  
    }
  }


  //when focus to element
  onFocusedRowChanged(e:any){
    if(e?.row){
      this.expandrows(e.row?.node)
      this.dxtree.instance.forEachNode((node:any) =>{
        this.expendarray.includes(node.key)? this.dxtree.instance.expandRow(node.key) :this.dxtree.instance.collapseRow(node.key);
        return;
      });
  
      if(e.row.node.children.length> 0){
        return
      }
      //to navigate to routing
      if(e.row?.data?.externalUrl=='tab'){      
        e.row.data.tabs.sort((a:{displayOrder:any,externalUrl:string}, b:{displayOrder:any,externalUrl:string}) => parseFloat(a.displayOrder) - parseFloat(b.displayOrder));
        this.router.navigate([e.row.data.tabs[0].externalUrl])  

      } 
      else if(e.row?.data?.externalUrl!="" || e.row?.data?.externalUrl!='undefined'){
      
        const targetRoute = e.row.data.externalUrl;

        // Construct the full URL
        const baseUrl = window.location.origin; // Get base URL (e.g., http://localhost:4200)
        const fullUrl = `${baseUrl}/#/${targetRoute}`; // Combine base URL with route
    
        // Open the route in a new tab
        window.open(fullUrl, '_blank');
        // this.router.navigate([e.row?.data?.externalUrl])
      }
  
      this.expendarray=[]
    
    }
    
  }

  expandrows(data:any){
    this.expendarray.push(data?.key)
    if(data?.level>0){
     this.expandrows(data?.parent)  
    }    
  }
}

@NgModule({
  imports: [ DxTreeViewModule ,SharedModule,RouterModule,CommonModule,
  BrowserModule],
  declarations: [ SideNavigationMenuComponent ],
  exports: [ SideNavigationMenuComponent ]
})
export class SideNavigationMenuModule { }
