<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item *ngIf="menuToggleEnabled" location="before" widget="dxButton" cssClass="menu-button" [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }">
    </dxi-item>
    <!-- <dxi-item
      location="center"
      cssClass="header-title"
      *ngIf="title"
      [text]="title">
    </dxi-item> -->
    <dxi-item location="after" locateInMenu="auto">
      <button class="master-back-btn" style="background: transparent;
      padding: 11px 31px;
      margin-left: 30px;
      margin-right: 30px;
      border: 1px solid #777;
      border-radius: 6px;" (click)="backToPrevious($event)">{{'backToPrevious'|translate}}</button>
    </dxi-item>
    <dxi-item location="after" widget="dxButton" locateInMenu="auto">
      <dx-menu #menu [dataSource]="data" displayExpr="nameAr" [showFirstSubmenuMode]="showSubmenuModes"
        orientation="horizontal" [hideSubmenuOnMouseLeave]="true" (onItemClick)="itemClick($event)">
      </dx-menu>
    </dxi-item>

    <dxi-item location="after" menuItemTemplate="themeSelector">
      <div *dxTemplate="let data of 'item'">
        <theme-selector></theme-selector>
      </div>
    </dxi-item>

    <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'menuItem'">
        <dx-button class="user-button authorization" width="210px" height="100%" stylingMode="text">
          <div *dxTemplate="let data of 'content'">
            <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>

    <dxi-item location="after" locateInMenu="auto">
      <div class="dx-field">
        <div class="dx-field-value">
          <dx-drop-down-button [splitButton]="false" [useSelectMode]="false" text={{Language|translate}} icon="globe"
            [items]="languageSettings" displayExpr="name" keyExpr="id"
            (onItemClick)="onItemClick($event)"></dx-drop-down-button>
        </div>
      </div>
    </dxi-item>
  </dx-toolbar>
</header>

<dx-popup [showTitle]="true" title="{{'content'|translate}}" [dragEnabled]="false" [hideOnOutsideClick]="true"
  [showCloseButton]="false" [(visible)]="popupVisible">
  <dxi-toolbar-item widget="dxButton" toolbar="top" location="after" [options]="closeButtonOptions">
  </dxi-toolbar-item>
  <dxo-position at="center" my="center" [of]="positionOf" collision="fit">
  </dxo-position>
  <dx-scroll-view width="100%" height="100%">
    <app-home-tab></app-home-tab>
  </dx-scroll-view>
</dx-popup>