import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { AuthService, IUser } from '../../services';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { Router } from '@angular/router';
import { TranslationService } from '../../services/i18n/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../modules/shared/shared.module';
import { SharedComponentModule } from 'src/app/shared-component/shared-component.module';
import { genericCustomStoreService } from '../../services/genericCustomStore.service';
import { DxMenuModule } from 'devextreme-angular';
import { ThemeSelectorModule } from "../theme-selector/theme-selector.component";
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  //menu
  sessiondata: any = []
  data: any;
  previousparent: any = []
  identifier: number = 0
  previous: number = 0
  showSubmenuModes: any = {}
  //
  popupVisible = false;
  closeButtonOptions: any;
  positionOf: string;
  backButtonOptions: any;
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  user: IUser | null = { userName: '' };

  userstatus: any = sessionStorage.getItem('data');

  userMenuItems = [
    {
      text: 'Profile',
      icon: 'user',
      onClick: () => {
        this.router.navigate(['/profile']);
      }
    },
    {
      text: this.userstatus == null ? 'Login' : 'Logout',
      icon: this.userstatus == null ? 'unlock' : 'runner',
      onClick: () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('data');
        sessionStorage.removeItem('username')
        this.authService.logOut();
      }
    }];

  languageSettings = [
    {
      id: 'en',
      name: window.localStorage.getItem("Language") == 'en' ? 'english' : 'اللغة الانجليزية'
    },
    {
      id: 'ar',
      name: window.localStorage.getItem("Language") == 'en' ? 'arabic' : 'اللغة العربية'
    }
  ]

  Language: any = window.localStorage.getItem("Language") == 'en' ? 'lang.en' : 'lang.ar'

  constructor(private authService: AuthService, public gt: genericCustomStoreService, private location: Location, private router: Router, public tanslationService: TranslationService, public translate: TranslateService) {
    this.gt.$HOMETAB.subscribe(res => {
      res ? this.popupVisible = false : ''
    })
    const that = this;
    this.closeButtonOptions = {
      icon: 'close',
      onClick(e: any) {
        that.popupVisible = false;
      },
    };
    this.backButtonOptions = {
      text: window.localStorage.getItem("Language") == 'en' ? 'Content' : 'محتوى',
      icon: 'spindown',
      onClick: () => {
        that.popupVisible = true;
      }
    }
    this.showSubmenuModes = {
      name: 'onHover',
      delay: { show: 0, hide: 300 },
    }
  }


  
  backToPrevious(e: any) {
    console.log(e);
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }

  async ngOnInit() {
    this.authService.getUser().then((e) => this.user = e.data);
    //menu
    this.sessiondata = await sessionStorage.getItem("data");
    this.data = JSON.parse(this.sessiondata);
    this.data = this.data?.navihationMenu
    //make parent and child from data
    if (this.data) {
      this.data.forEach((ele: any) => {
        ele.items = []
        this.data.filter((filterele: any) => {
          ele.id == filterele.parentId ? ele.items.push(filterele) : ''
        })
      })

      //filter data 
      this.data.forEach((ele: any) => {
        this.data = this.data.filter((res: any) => {
          return !ele.items.includes(res)
        })
      })

    }
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  onItemClick(e: any) {
    this.tanslationService?.changeLang(e.itemData.id)
  }

  itemClick(e: any) {
    this.router.navigate([e.itemData.externalUrl])
  }
}


@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    SharedModule,
    SharedComponentModule,
    DxMenuModule,
    ThemeSelectorModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule { }
