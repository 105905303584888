
export enum ScreenNameEnum {
    StoreTransferRequest = 0,
    StoreTransferOrder = 1,
    StoreTransferAccept = 2,
    OutVoucher = 3,
    InVoucher = 4,
    SellPriceList = 5,
    SellOrder = 6,
    Selldeliveryorder = 7,
    Document = 8,
    BuyDeliveryRequest = 9,
    BuyOrder = 10,
    BuyDeliveryOrder = 11,
    PurchaseInvoice = 12,

    StoreTransfer = 13,

    ReturnDocument = 14,

    OldReturnDocument = 15 ,

    ReturnPurchaseInvoice = 16 ,

    OldReturnPurchaseInvoice = 17
    


}