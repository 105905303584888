import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common'
import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, ResetPasswordFormModule, CreateAccountFormModule, ChangePasswordFormModule, LoginFormModule, HeaderModule} from './shared/components';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxChartModule, DxDropDownButtonModule, DxFormModule, DxTabPanelModule, DxToolbarModule } from 'devextreme-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/modules/shared/shared.module';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { CoreModule } from './core/core.module';
import { DxButtonModule } from 'devextreme-angular';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { NavigationMenuComponent } from './pages/navigation-menu/navigation-menu.component';
import { EditNavigationMenuComponent } from './pages/navigation-menu/edit-navigation-menu/edit-navigation-menu.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { HomeComponent } from './pages/home/home.component';
import { SharedComponentModule } from './shared-component/shared-component.module';
import { SuperComponent } from './pages/super/super.component';
import { CommComponent } from './comm/comm.component';
import { ChangeEmployeeStatusComponent } from './pages/hr/change-employee-status/change-employee-status.component';
import { ThemeService } from './theme.service';
import { ScreenService } from './shared/services';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    NavigationMenuComponent,
    EditNavigationMenuComponent,
    ServerErrorComponent,
    HomeComponent,
    SuperComponent,
    CommComponent,
    ChangeEmployeeStatusComponent,
  
    // MainTitleComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    ResetPasswordFormModule,
    CreateAccountFormModule,
    ChangePasswordFormModule,
    LoginFormModule,
    HeaderModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DxFormModule,
    DxDropDownButtonModule,
    DxToolbarModule,
    SharedModule,
    CoreModule,
    DxButtonModule,
    SharedModule,
    DxReportViewerModule,
    SharedComponentModule,
    DxTabPanelModule,
    DxChartModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers:[ThemeService,
    ScreenService],

  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
