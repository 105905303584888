import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';


@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(public translate:TranslateService) { }

  notifyfun(action:string){
    notify({
      message: this.translate.instant(`notify.${action}`),
      height: 45,
      width: 900,
      minWidth: 150,
      type: 'success',
      displayTime: 1500,
      animation: {
        show: {
          type: 'fade', duration: 200, from: 0, to: 1,
        },
        hide: { type: 'fade', duration: 200, to: 0 },
      },
    });

  }

}
