import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { AuthService } from '../../services';
import { HttpErrorHandler, HandleError } from 'src/app/shared/services/http-error-handler.service';
import { SharedModule } from '../../modules/shared/shared.module';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  loading = false;
  formData: any = {};
  private handleError: HandleError;
  constructor(private authService: AuthService, private router: Router,httpErrorHandler: HttpErrorHandler,public translate:TranslateService) {
    this.handleError = httpErrorHandler.createHandleError('LoginService');
  }

  // ngOnInit() {
  //    if (sessionStorage.getItem('token') != null && window.sessionStorage.getItem('data'))
  //      this.router.navigateByUrl('/home');       
  //  }

  ngOnInit() {
    const token = sessionStorage.getItem('token');
    const data = sessionStorage.getItem('data');
    if (token && data) {
      this.router.navigate(['/home']);
    }
  }
  

  async onSubmit(e: Event) {
    e.preventDefault();
    this.loading = true;
    const result = await this.authService.logIn(this.formData)
      if (!result.isOk) {

        this.loading = false; // Stop loading on error
        return;
       }
  
  }

  onCreateAccountClick = () => {
    this.router.navigate(['/create-account']);
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    SharedModule
    ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }
