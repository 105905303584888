export const environment = {
  production: true,
      //apiRoot:'https://localhost:7074'
     // apiRoot:'https://localhost:7074'
 //apiRoot: 'http://172.16.0.13:4500'
  // apiRoot: 'http://172.16.0.44:44389'
//apiRoot:'https://erpback.pioneers-solutions.info'
 //apiRoot:'https://honestbak.pioneers-solutions.info'
  // apiRoot: 'https://172.16.0.13:8090'
  //  apiRoot: 'https://172.16.0.13'
      // apiRoot:'http://cdcback.runasp.net'
      // apiRoot: 'http://cdcback.runasp.net'
        // apiRoot: 'https://elrabwabak.pioneers-solutions.info'
            
  apiRoot: 'https://cdcbak.pioneers-solutions.info'
             
       


};
