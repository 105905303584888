import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxBoxModule, DxButtonModule, DxCheckBoxModule, DxContextMenuModule, DxDataGridModule, DxDateBoxModule, DxDrawerModule, DxDropDownBoxModule, DxDropDownButtonModule, DxFormModule, DxListModule, DxLoadIndicatorModule, DxLoadPanelModule, DxLookupModule, DxNumberBoxModule, DxPopupModule, DxRadioGroupModule, DxScrollViewModule, DxSelectBoxModule, DxSpeedDialActionModule, DxSwitchModule, DxTabPanelModule, DxTabsModule, DxTagBoxModule, DxTemplateModule, DxTextBoxModule, DxToolbarModule, DxValidationGroupModule, DxValidatorModule } from 'devextreme-angular';
import { DxoRemoteOperationsModule } from 'devextreme-angular/ui/nested';
import { TransitionChildModule } from '../../services/transition child/transition-child/transition-child.module';
import { DxTreeListModule } from 'devextreme-angular';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { DxTooltipModule} from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxFileUploaderModule } from "devextreme-angular";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxLoadPanelModule,
    DxSpeedDialActionModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTabsModule,   
    DxoRemoteOperationsModule,
    TransitionChildModule,
    DxToolbarModule,
    DxDropDownButtonModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule,
    DxNumberBoxModule,
    DxTabPanelModule,
    DxFormModule,
    DxLookupModule,
    DxRadioGroupModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxSwitchModule,
    DxScrollViewModule,
    DxDropDownBoxModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxContextMenuModule,
    DxTreeListModule,
    DxTooltipModule,
    DxListModule,
    DxDrawerModule,
    DxBoxModule,
    DxLoadIndicatorModule,
    DxValidationGroupModule,
    FormsModule,
    ReactiveFormsModule,
    DxFileUploaderModule,
    DxDataGridModule, 
    
    
  ],
  exports:[
    DxDataGridModule,
    DxLoadPanelModule,
    DxSpeedDialActionModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTabsModule,
    TransitionChildModule,
    DxToolbarModule,
    DxDropDownButtonModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule,
    DxNumberBoxModule,
    DxTabPanelModule,
    DxFormModule,
    DxLookupModule,
    DxRadioGroupModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxSwitchModule,
    DxScrollViewModule,
    DxDropDownBoxModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxContextMenuModule,
    DxTreeListModule,
    DxReportViewerModule,
    DxTooltipModule,
    DxListModule,
    DxDrawerModule,
    DxBoxModule,
    DxLoadIndicatorModule,
    DxValidationGroupModule,
    FormsModule,
    ReactiveFormsModule,
    DxFileUploaderModule,
    DxDataGridModule,
    ]
})
export class SharedModule { }
