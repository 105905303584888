


import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectsService {
  // Properties to store translated values
  public TypeValue: any[] = [];
  public TaxableStatus: any[] = [];
  public InsuranceStatus: any[] = [];
  public penalityReason: any[] = [];
  public button: any = {};
  public businessType: any[] = [];
  public employeeWorkStatus: any[] = [];
  public personalType: any[] = [];
  public delayRuleType: any[] = [];
  public durationType: any[] = [];
  public absenceRules: any[] = [];
  public Attending_Leaving: any[] = [];
  public cireChoice: any[] = [];
  public accountNature: any[] = [];
  public FinalAccountsTypes: any[] = [];
  public AccountType: any[] = [];
  public fixedOrChangableType: any[] = [];
  public WeekDaysList: any[] = [];
  public MonthsList: any[] = [];
  public ageType: any[] = [];
  public businessTyp: any[] = [];
  public Vendortabs: any[] = [];
  public VoucherVendor: any[] = [];
  public VoucherCustomer: any[] = [];
  public Voucher: any[] = [];
  public OUT_Voucher: any[] = [];
  public dueDateType: any[] = [];
  public discountType: any[] = [];
  public debitLimit: any[] = [];

  constructor(public translate: TranslateService) {
    this.initializeTranslations();
  }

  private initializeTranslations() {
    // Initial translation
    this.updateAllTranslations();

    // Subscribe to language changes
    this.translate.onLangChange.subscribe(() => {
      this.updateAllTranslations();
    });
  }

  private updateAllTranslations() {
    this.TypeValue = [
      { id: 1, name: this.translate.instant('tableinfo.valuechoose') },
      { id: 2, name: this.translate.instant('tableinfo.percentagechoose') }
    ];

    this.TaxableStatus = [
      { id: 1, name: this.translate.instant('tableinfo.tax') },
      { id: 2, name: this.translate.instant('tableinfo.notax') }
    ];

    this.InsuranceStatus = [
      { id: 1, name: this.translate.instant('tableinfo.insurance') },
      { id: 2, name: this.translate.instant('tableinfo.noinsurance') }
    ];

    this.penalityReason = [
      { id: 1, name: this.translate.instant('tableinfo.penalityReason.Punishment') },
      { id: 2, name: this.translate.instant('tableinfo.penalityReason.Forgotattendance') },
      { id: 3, name: this.translate.instant('tableinfo.penalityReason.Forgetleaving') },
      { id: 4, name: this.translate.instant('tableinfo.penalityReason.absence') },
      { id: 5, name: this.translate.instant('tableinfo.penalityReason.other') }
    ];

    this.button = {
      saveRowChanges: this.translate.instant('tableinfo.savebutton'),
      cancelRowChanges: this.translate.instant('tableinfo.cancelbutton')
    };

    this.businessType = [
      { id: 1, name: this.translate.instant('basic-info.company.Commercial') }
    ];

    this.employeeWorkStatus = [
      { id: 1, name: this.translate.instant('addemployee.onjob') },
      { id: 2, name: this.translate.instant('addemployee.pension') }
    ];

    this.personalType = [
      { id: 1, name: this.translate.instant('addemployee.male') },
      { id: 2, name: this.translate.instant('addemployee.famale') }
    ];

    this.delayRuleType = [
      { id: 1, name: this.translate.instant('workSystemSetting.DeductionRules.delayRuleType.minutetominute') },
      { id: 2, name: this.translate.instant('workSystemSetting.DeductionRules.delayRuleType.minutetovalue') },
      { id: 3, name: this.translate.instant('workSystemSetting.DeductionRules.delayRuleType.minutetohour') },
      { id: 4, name: this.translate.instant('workSystemSetting.DeductionRules.delayRuleType.minutetopercentage') }
    ];

    this.durationType = [
      { id: 1, name: this.translate.instant('workSystemSetting.DeductionRules.durationType.all') },
      { id: 2, name: this.translate.instant('workSystemSetting.DeductionRules.durationType.beforeleavingdate') },
      { id: 3, name: this.translate.instant('workSystemSetting.DeductionRules.durationType.afterattendingdate') }
    ];

    this.absenceRules = [
      { id: 1, name: this.translate.instant('workSystemSetting.DeductionRules.absenceRules.dayToDay') },
      { id: 2, name: this.translate.instant('workSystemSetting.DeductionRules.absenceRules.daytovalue') },
      { id: 3, name: this.translate.instant('workSystemSetting.DeductionRules.absenceRules.daytosalary') },
      { id: 4, name: this.translate.instant('workSystemSetting.DeductionRules.absenceRules.daytoPercentage') }
    ];

    this.Attending_Leaving = [
      { id: 1, name: this.translate.instant('AttendingLeaving.Attending') },
      { id: 2, name: this.translate.instant('AttendingLeaving.Leaving') },
      { id: 3, name: this.translate.instant('AttendingLeaving.Attending_Leaving') }
    ];

    this.cireChoice = [
      { id: 1, name: this.translate.instant('addemployee.tab4.total') },
      { id: 2, name: this.translate.instant('addemployee.tab4.main') },
      { id: 3, name: this.translate.instant('addemployee.tab4.s_value') }
    ];

    this.accountNature = [
      { id: 1, name: this.translate.instant('accounting.Creditanddepit') },
      { id: 2, name: this.translate.instant('accounting.Credit') },
      { id: 3, name: this.translate.instant('accounting.depit') }
    ];

    this.FinalAccountsTypes = [
      { id: 1, name: this.translate.instant('accounting.Assets') },
      { id: 2, name: this.translate.instant('accounting.Liabilities') },
      { id: 3, name: this.translate.instant('accounting.Income') },
      { id: 4, name: this.translate.instant('accounting.Outcome') }
    ];

    this.AccountType = [
      { id: 101, name: this.translate.instant('accounting.Customers'), finalAccountsTypes: 1 },
      { id: 106, name: this.translate.instant('accounting.Safe'), finalAccountsTypes: 1 },
      { id: 103, name: this.translate.instant('accounting.Bank'), finalAccountsTypes: 1 },
      { id: 104, name: this.translate.instant('accounting.FixedAssets'), finalAccountsTypes: 1 },
      { id: 105, name: this.translate.instant('accounting.Cheeque'), finalAccountsTypes: 1 },
      { id: 102, name: this.translate.instant('accounting.OtherAssets'), finalAccountsTypes: 1 },
      { id: 201, name: this.translate.instant('accounting.Vendors'), finalAccountsTypes: 2 },
      { id: 203, name: this.translate.instant('accounting.Capital'), finalAccountsTypes: 2 },
      { id: 202, name: this.translate.instant('accounting.OtherLiabilities'), finalAccountsTypes: 2 },
      { id: 204, name: this.translate.instant('accounting.Allocations'), finalAccountsTypes: 2 },
      { id: 205, name: this.translate.instant('accounting.Profit'), finalAccountsTypes: 2 },
      { id: 301, name: this.translate.instant('accounting.OutComes'), finalAccountsTypes: 3 },
      { id: 302, name: this.translate.instant('accounting.OtherOutComes'), finalAccountsTypes: 3 },
      { id: 303, name: this.translate.instant('accounting.CurrencySpreads'), finalAccountsTypes: 3 },
      { id: 401, name: this.translate.instant('accounting.InComes'), finalAccountsTypes: 4 },
      { id: 402, name: this.translate.instant('accounting.OtherInComes'), finalAccountsTypes: 4 }
    ];

    this.fixedOrChangableType = [
      { id: 0, name: this.translate.instant('salary.fixed') },
      { id: 1, name: this.translate.instant('salary.changable') }
    ];

    this.WeekDaysList = [
      { id: 0, nameAr: this.translate.instant('WeekDaysList.Sunday') },
      { id: 1, nameAr: this.translate.instant('WeekDaysList.Monday') },
      { id: 2, nameAr: this.translate.instant('WeekDaysList.Tuesday') },
      { id: 3, nameAr: this.translate.instant('WeekDaysList.Wednesday') },
      { id: 4, nameAr: this.translate.instant('WeekDaysList.Thursday') },
      { id: 5, nameAr: this.translate.instant('WeekDaysList.Friday') },
      { id: 6, nameAr: this.translate.instant('WeekDaysList.Saturday') }
    ];

    this.MonthsList = [
      { id: 1, nameAr: this.translate.instant('MonthsList.January') },
      { id: 2, nameAr: this.translate.instant('MonthsList.February') },
      { id: 3, nameAr: this.translate.instant('MonthsList.March') },
      { id: 4, nameAr: this.translate.instant('MonthsList.April') },
      { id: 5, nameAr: this.translate.instant('MonthsList.May') },
      { id: 6, nameAr: this.translate.instant('MonthsList.June') },
      { id: 7, nameAr: this.translate.instant('MonthsList.July') },
      { id: 8, nameAr: this.translate.instant('MonthsList.August') },
      { id: 9, nameAr: this.translate.instant('MonthsList.September') },
      { id: 10, nameAr: this.translate.instant('MonthsList.October') },
      { id: 11, nameAr: this.translate.instant('MonthsList.November') },
      { id: 12, nameAr: this.translate.instant('MonthsList.December') }
    ];

    this.ageType = [
      { id: 1, nameAr: this.translate.instant('approval.year') },
      { id: 2, nameAr: this.translate.instant('approval.month') }
    ];

    this.businessTyp = [
      { id: 1, nameAr: this.translate.instant('purchases.Bussines') },
      { id: 2, nameAr: this.translate.instant('purchases.NaturalPerson') },
      { id: 3, nameAr: this.translate.instant('purchases.Foreigner') }
    ];

    this.Vendortabs = [
      { id: 0, text: this.translate.instant('purchases.commercialdata') },
      { id: 1, text: this.translate.instant('purchases.vendoraccount') },
      { id: 2, text: this.translate.instant('purchases.responsiblePeople') },
      { id: 3, text: this.translate.instant('purchases.vendorBranches') },
      { id: 4, text: this.translate.instant('purchases.vendorDueDates') },
      { id: 5, text: this.translate.instant('purchases.addedTaxies') }
    ];

    this.VoucherVendor = [
      { id: 0, text: this.translate.instant('accounting.debitDueDatesVendor') },
      { id: 1, text: this.translate.instant('accounting.creditDueDatesVendor') },
      { id: 2, text: this.translate.instant('accounting.debitPaidDueDatesVendor') },
      { id: 3, text: this.translate.instant('accounting.creditPaidDueDatesVendor') },
      { id: 4, text: this.translate.instant('accounting.paymentMethods') }
    ];

    this.VoucherCustomer = [
      { id: 0, text: this.translate.instant('accounting.debitDueDatesCustomer') },
      { id: 1, text: this.translate.instant('accounting.creditDueDatesCustomer') },
      { id: 2, text: this.translate.instant('accounting.debitPaidDueDatesCustomer') },
      { id: 3, text: this.translate.instant('accounting.creditPaidDueDatesCustomer') },
      { id: 4, text: this.translate.instant('accounting.paymentMethods') }
    ];

    this.Voucher = [
      { id: 0, text: this.translate.instant('accounting.debitDueDatesVendor') },
      { id: 1, text: this.translate.instant('accounting.creditDueDatesVendor') },
      { id: 2, text: this.translate.instant('accounting.debitPaidDueDatesVendor') },
      { id: 3, text: this.translate.instant('accounting.creditPaidDueDatesVendor') }
    ];

    this.OUT_Voucher = [
      { id: 0, text: this.translate.instant('accounting.debitDueDatesCustomer') },
      { id: 1, text: this.translate.instant('accounting.creditDueDatesCustomer') },
      { id: 2, text: this.translate.instant('accounting.debitPaidDueDatesCustomer') },
      { id: 3, text: this.translate.instant('accounting.creditPaidDueDatesCustomer') }
    ];

    this.dueDateType = [
      { id: 1, text: this.translate.instant('accounting.BeginBalance') },
      { id: 2, text: this.translate.instant('accounting.Invoice') },
      { id: 3, text: this.translate.instant('accounting.Paper') }
    ];

 
    
    this.discountType=[
      { id: 0, text: this.translate.instant('purchases.nofound') },
      { id: 1, text: this.translate.instant('tableinfo.percentagechoose') },
      { id: 2, text: this.translate.instant('tableinfo.valuechoose') },
     ]
    
     this.debitLimit=[
      { id: 1, text: this.translate.instant('purchases.nolimit') },
      { id: 2, text: this.translate.instant('purchases.Stop') },
      { id: 3, text: this.translate.instant('purchases.warning') },
      { id: 4, text: this.translate.instant('purchases.statement') },
    
     ]
  }
}