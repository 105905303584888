import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DataShareService } from 'src/app/shared/services/data-share.service';
import { genericCustomStoreService } from 'src/app/shared/services/genericCustomStore.service';
import { ObjectsService } from 'src/app/shared/services/hr/objects.service';
import { loadMessages } from 'devextreme/localization';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  button = {};
  customDataSource: any;
  date = new Date()
  addpopup: string = ''
  LOCALIZATION_LOCAL_STORAGE_KEY = "Language";
  currentLang: any;
  validationform: boolean = false
  businessType:{}[]=[]
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  constructor(public gt: genericCustomStoreService, public route: ActivatedRoute,public Objects: ObjectsService, public translate: TranslateService,
     public DataShareService: DataShareService) {
    gt.seturl("Company");
    gt.submit();
    this.customDataSource = gt.customDataSource;
    this.button = Objects.button;
    this.businessType = Objects.businessType;
    this.currentLang = localStorage.getItem(
      this.LOCALIZATION_LOCAL_STORAGE_KEY
    );

    loadMessages({
      en: { "Yes": this.translate.instant('personal.yes'), "No": this.translate.instant('personal.no') }
    });

  }
  ngOnInit(): void { this.DataShareService.removeServerError() }


  updateRow(options: any) {
    options.newData = Object.assign(options.oldData, options.newData);
  }
  onInitNewRow(e: any) {
    this.addpopup = this.translate.instant('tableinfo.addbutton')
  }
  onEditingStart(e: any) {
    this.addpopup = this.translate.instant('tableinfo.editbutton');
  }

  addrowfun(name: string) {
    return this.gt.addrow(name)
  }

}
